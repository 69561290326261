import React, { useState } from 'react';
import { AiOutlineCheck } from 'react-icons/ai';

const ContactForm: React.FC = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [errors, setErrors] = useState<{
    nombre: boolean;
    correo: boolean;
    telefono: boolean;
  }>({
    nombre: false,
    correo: false,
    telefono: false,
  });

  const [formData, setFormData] = useState({
    nombre: '',
    correo: '',
    telefono: '',
    empresa: '',
    comentario: '',
    hora_de_envio: new Date().toLocaleString(),
  });

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    // Limpiar el error cuando el usuario empieza a escribir
    if (errors[name as keyof typeof errors]) {
      setErrors((prev) => ({ ...prev, [name]: false }));
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    // Validación local
    const nombreError = formData.nombre.trim() === '';
    const correoError = formData.correo.trim() === '';
    const telefonoError = formData.telefono.trim() === '';

    setErrors({
      nombre: nombreError,
      correo: correoError,
      telefono: telefonoError,
    });

    if (nombreError || correoError || telefonoError) {
      return; // No se envía si faltan campos requeridos
    }

    setIsSubmitting(true);

    // Envío del formulario
    fetch(
      'https://api.sheetbest.com/sheets/68a454f1-0867-40cf-9053-be15d52cbc7d',
      {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          'X-API-KEY':
            'AXbuYsoFKQMnJ67CdFDebScH5APf3R2yTLFPJz3pT8Ka_DbXpK3ATzeYowANSGRw',
        },
        body: JSON.stringify(formData),
      },
    )
      .then((r) => r.json())
      .then((data) => {
        console.log('Respuesta del servidor:', data);
        setIsSubmitting(false);
        setIsSubmitted(true);
        // Limpia el formulario
        setFormData({
          nombre: '',
          correo: '',
          telefono: '',
          empresa: '',
          comentario: '',
          hora_de_envio: new Date().toLocaleString(),
        });
      })
      .catch((error) => {
        console.log('Error al enviar el formulario:', error);
        setIsSubmitting(false);
      });
  };

  const closeModal = () => {
    setIsSubmitted(false);
  };

  return (
    <section className="w-full min-h-screen bg-[#050505] flex items-center justify-center p-10">
      <div className="flex flex-col items-center w-full md:w-3/5 mt-20">
        <div className="text-4xl md:text-5xl font-black flex flex-col items-center md:flex-row md:justify-center mb-6">
          <h1 className="text-white">ESTEMOS EN</h1>
          <h1 className="italic text-yellow md:before:content-[' '] md:before:inline-block md:before:w-4">
            CONTACTO
          </h1>
        </div>
        <div className="text-center text-lg text-[#909090] w-[290px] md:w-[550px] mb-6 leading-tight">
          <p>
            Completa el formulario y nos pondremos en contacto contigo para
            conversar sobre tu proyecto y sus requerimientos
          </p>
        </div>
        <form
          onSubmit={handleSubmit}
          className="flex flex-col items-center w-full space-y-6 mt-5 text-lg text-[#B4B4B4] px-10 md:px-0"
        >
          <div className="flex flex-col md:flex-row space-y-8 md:space-y-0 w-full gap-x-6">
            <div className="flex flex-col w-full md:w-1/2">
              <input
                type="text"
                name="nombre"
                placeholder="Nombre *"
                value={formData.nombre}
                onChange={handleChange}
                className={`bg-[#0D0D0D] placeholder-[#B4B4B4] text-[#B4B4B4] w-full h-14 px-4 rounded ${
                  errors.nombre ? 'border border-red-500' : ''
                }`}
              />
              {errors.nombre && (
                <span className="text-red-500 text-sm mt-1">
                  Este campo es obligatorio.
                </span>
              )}
            </div>
            <div className="flex flex-col w-full md:w-1/2">
              <input
                type="email"
                name="correo"
                placeholder="Correo *"
                value={formData.correo}
                onChange={handleChange}
                className={`bg-[#0D0D0D] placeholder-[#B4B4B4] w-full h-14 px-4 rounded ${
                  errors.correo ? 'border border-red-500' : ''
                }`}
              />
              {errors.correo && (
                <span className="text-red-500 text-sm mt-1">
                  Este campo es obligatorio.
                </span>
              )}
            </div>
          </div>
          <div className="flex flex-col md:flex-row space-y-8 md:space-y-0 w-full gap-x-6">
            <div className="relative w-full md:w-1/2 flex flex-col">
              {/* <span className="absolute top-1/2 left-4 transform -translate-y-1/2 text-[#B4B4B4] pointer-events-none">
                +56
              </span> */}
              <input
                type="tel"
                name="telefono"
                placeholder="Teléfono *"
                value={formData.telefono}
                onChange={handleChange}
                className={`bg-[#0D0D0D] placeholder-[#B4B4B4] w-full h-14 px-4 rounded ${
                  errors.telefono ? 'border border-red-500' : ''
                }`}
              />
              {errors.telefono && (
                <span className="text-red-500 text-sm mt-1">
                  Este campo es obligatorio.
                </span>
              )}
            </div>
            <input
              type="text"
              name="empresa"
              placeholder="Empresa"
              value={formData.empresa}
              onChange={handleChange}
              className="bg-[#0D0D0D] placeholder-[#B4B4B4] w-full md:w-1/2 h-14 px-4 rounded"
            />
          </div>
          <textarea
            name="comentario"
            placeholder="Comentános en qué te podemos ayudar"
            value={formData.comentario}
            onChange={handleChange}
            className="hidden md:block bg-[#0D0D0D] placeholder-[#B4B4B4] w-full h-64 max-h-96 min-h-14 px-4 py-2 rounded"
          />
          <button
            type="submit"
            disabled={isSubmitting}
            className="relative w-full h-14 text-white font-bold rounded border border-[#FCFCFD] overflow-hidden group disabled:opacity-50 disabled:cursor-not-allowed"
          >
            <span className="absolute inset-0 bg-yellow transform origin-bottom scale-y-0 group-hover:scale-y-100 transition-all duration-700 ease-in-out"></span>
            <span className="relative z-10 group-hover:text-white">
              {isSubmitting ? (
                <AiOutlineCheck className="text-3xl animate-bounce mx-auto" />
              ) : (
                <span className="flex justify-center">ENVIAR</span>
              )}
            </span>
          </button>
        </form>
        {/* Modal de Confirmación */}
        {isSubmitted && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="bg-[#0D0D0D] p-6 rounded-lg shadow-lg max-w-sm w-full text-center">
              <h2 className="text-2xl text-white mb-4">¡Gracias!</h2>
              <p className="text-[#B4B4B4] mb-6">
                ¡Nos contactaremos contigo a la brevedad!
              </p>
              <button
                onClick={closeModal}
                className="bg-yellow text-black px-4 py-2 rounded hover:bg-yellow-600 transition"
              >
                Cerrar
              </button>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default ContactForm;
